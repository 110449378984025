import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import HomeRow from '../../components/homeRow';
import HomeMobileRow from '../../components/homeMobileRow';
import HomeHero from '../../components/homeHero';
import HomeScroll from '../../components/homeScroll';
import HomeMobile from '../../components/homeMobile';
import HomeStatic from '../../components/homeStatic';
import HomeFooterSignUp from '../../components/homeFooterSignUp';
import { titles, descriptions } from '../../const/metaData';
import { Content } from '../../styles/commonStyles';
import { graphql } from 'gatsby';
import { getScreenData } from '../../services/prismicConverters';

export type HomeProps = {
  data: any;
};

const Home = ({
  data: {
    allPrismicHomepage: { edges },
  },
}: HomeProps) => {
  const data = edges[0].node.data;
  const screenData = [
    getScreenData(data.screen_1.raw),
    getScreenData(data.screen_2.raw),
    getScreenData(data.screen_3.raw),
    getScreenData(data.screen_4.raw),
    getScreenData(data.screen_5.raw),
  ];

  return (
    <Layout>
      <SEO title={titles.home} description={descriptions.home} />
      <Content>
        <HomeHero data={getScreenData(data.hero.raw)} />
        <HomeRow data={getScreenData(data.second.raw)} id="skint" />
        <HomeMobileRow data={getScreenData(data.second.raw)} />
        <HomeScroll data={screenData} />
        <HomeMobile data={screenData} />
        <HomeRow data={{ image: data.third.raw[2].url }} id="transfer" />
        <HomeMobileRow data={{ image: data.third.raw[2].url }} />
        <HomeStatic />
        <HomeFooterSignUp />
      </Content>
    </Layout>
  );
};

export const homePageQuery = graphql`
  {
    allPrismicHomepage {
      edges {
        node {
          data {
            hero {
              raw
            }
            second {
              raw
            }
            screen_1 {
              raw
            }
            screen_2 {
              raw
            }
            screen_3 {
              raw
            }
            screen_4 {
              raw
            }
            screen_5 {
              raw
            }
            third {
              raw
            }
          }
        }
      }
    }
  }
`;

export default Home;
